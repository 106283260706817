import { PrismicPreview } from '@prismicio/next';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import { AppProps } from 'next/app';
import Script from 'next/script';
import { useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { i18nConfig } from '../next-i18next.config';
import SEO from '../next-seo.config';
import { repositoryName } from '../prismic-configuration';

import { PrismicProvider } from '@prismicio/react';
import Link from 'next/link';

import './styles.css';

const CustomApp = ({ Component, pageProps }: AppProps): JSX.Element => {
  useEffect(() => {
    AOS.init();
    window.addEventListener('load', AOS.refresh);
  }, []);
  return (
    <>
      <DefaultSeo {...SEO} />
      {/* <Head> */}
      <Script
        id="googletagmanager"
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-FFX105LS8Y"
      ></Script>
      {/* </Head> */}
      <div className="h-full app">
        <main className="bg-dark font-body">
          <PrismicProvider
            internalLinkComponent={(props) => <Link {...props} />}
          >
            <PrismicPreview repositoryName={repositoryName}>
              <Component {...pageProps} />
            </PrismicPreview>
          </PrismicProvider>
        </main>
      </div>
    </>
  );
};

export default appWithTranslation(CustomApp, i18nConfig);
