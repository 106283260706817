const path = require('path');

/**
 * @type {import('next-i18next').UserConfig}
 **/

export const i18nConfig = {
  i18n: {
    locales: ['en-GB', 'nl-NL', 'es-ES'],
    defaultLocale: 'en-GB',
  },
  debug: process.env.VERCEL_ENV === 'development' && !process.env.E2E,
  cache: true,
  ns: [
    'projects',
    'careers',
    'common',
    'contact',
    'form',
    'homepage',
    'services',
    'errors',
  ],
  localePath:
    typeof window === 'undefined'
      ? path.resolve().includes('apps/business-website')
        ? path.resolve('public/locales')
        : path.resolve('apps/business-website/public/locales')
      : '/locales',
};

// module.exports = i18nConfig;
