/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import * as prismic from '@prismicio/client';
import * as prismicNext from '@prismicio/next';

export const repositoryName = 'prickle-website';
export const accessToken = process.env.PRISMIC_ACCESS_TOKEN;

export const accessTypes = process.env.PRISMIC_ACCESS_TYPE;

const apiEndpoint = prismic.getRepositoryEndpoint(repositoryName);
// Client method to query documents from the Prismic repo

export const Client = (config = {}) => {
  const client = prismic.createClient(apiEndpoint, { accessToken, ...config });
  prismicNext.enableAutoPreviews({
    client,
    previewData: config.previewData,
    req: config.req,
  });
  return client;
};

export const useLocal = async () => {
  const clientRepository = prismic.createClient(apiEndpoint);
  const repository = await clientRepository.getRepository();
  const locales = repository.languages.map((lang) => lang.id);
  return locales;
};
